import React from "react";
import { Routes, Route } from "react-router";
import HomeView from "./components/HomeView.tsx";
import FormView from "./components/FormView.tsx";
import SigninView from "./components/SigninView.tsx";
import DnDView from "./components/DnDView.tsx";
import { DnDNestingView } from "./components/DnDNestingView.tsx";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/form" element={<FormView />} />
      <Route path="/signin" element={<SigninView />} />
      <Route path="/dnd" element={<DnDView />} />
      <Route path="/dnd-nesting" element={<DnDNestingView />} />
    </Routes>
  );
};

export default App;

import React, { memo, useCallback, useMemo, useState } from 'react';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { DragSourceMonitor, DropTargetMonitor } from 'react-dnd';

const Colors = {
  YELLOW: 'yellow',
  BLUE: 'blue',
};

const baseStyle: CSSProperties = {
  border: '1px solid gray',
  padding: '2rem',
  textAlign: 'center',
};

export interface SourceBoxProps {
  color: string;
  children?: ReactNode;
}

const SourceBox: FC<SourceBoxProps> = memo(({ color, children }) => {
  const [forbidDrag, setForbidDrag] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: color,
    canDrag: !forbidDrag,
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [forbidDrag, color]);

  const toggleForbidDrag = useCallback(() => {
    setForbidDrag((prev) => !prev);
  }, []);

  const backgroundColor = useMemo(() => {
    return color === Colors.YELLOW ? 'lightgoldenrodyellow' : 'lightblue';
  }, [color]);

  const containerStyle = useMemo(() => ({
    ...baseStyle,
    backgroundColor,
    opacity: isDragging ? 0.4 : 1,
    cursor: forbidDrag ? 'default' : 'move',
  }), [isDragging, forbidDrag, backgroundColor]);

  return (
    <div ref={drag} style={containerStyle} role="SourceBox" data-color={color}>
      <input type="checkbox" checked={forbidDrag} onChange={toggleForbidDrag} />
      <small>Forbid drag</small>
      {children}
    </div>
  );
});

export interface DragItem {
  type: string;
}

export interface TargetBoxProps {
  onDrop: (item: string) => void;
  lastDroppedColor?: string;
}

const TargetBox: FC<TargetBoxProps> = memo(({ onDrop, lastDroppedColor }) => {
  const [{ isOver, draggingColor, canDrop }, drop] = useDrop(() => ({
    accept: [Colors.YELLOW, Colors.BLUE],
    drop: (_item: DragItem, monitor) => {
      onDrop(monitor.getItemType() as string);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      draggingColor: monitor.getItemType() as string,
    }),
  }), [onDrop]);

  const backgroundColor = useMemo(() => {
    if (draggingColor === Colors.BLUE) return 'lightblue';
    if (draggingColor === Colors.YELLOW) return 'lightgoldenrodyellow';
    return '#fff';
  }, [draggingColor]);

  const containerStyle = useMemo(() => ({
    ...baseStyle,
    backgroundColor,
    opacity: isOver ? 1 : 0.7,
  }), [isOver, backgroundColor]);

  return (
    <div
      ref={drop}
      style={containerStyle}
      role="TargetBox"
      data-color={lastDroppedColor || 'none'}
    >
      <p>Drop here.</p>
      {!canDrop && lastDroppedColor && <p>Last dropped: {lastDroppedColor}</p>}
    </div>
  );
});

const StatefulTargetBox: FC = () => {
  const [lastDroppedColor, setLastDroppedColor] = useState<string | null>(null);

  const handleDrop = useCallback((color: string) => {
    setLastDroppedColor(color);
  }, []);

  return (
    <TargetBox lastDroppedColor={lastDroppedColor || undefined} onDrop={handleDrop} />
  );
};

export const DnDNestingView: FC = memo(() => {
  return (
    <div style={{ overflow: 'hidden', clear: 'both', margin: '-0.5rem' }}>
      <div style={{ float: 'left' }}>
        <SourceBox color={Colors.BLUE}>
          <SourceBox color={Colors.YELLOW}>
            <SourceBox color={Colors.YELLOW} />
            <SourceBox color={Colors.BLUE} />
          </SourceBox>
          <SourceBox color={Colors.BLUE}>
            <SourceBox color={Colors.YELLOW} />
          </SourceBox>
        </SourceBox>
      </div>
      <div style={{ float: 'left', marginLeft: '5rem', marginTop: '0.5rem' }}>
        <StatefulTargetBox />
      </div>
    </div>
  );
});
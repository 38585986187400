import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Shield, Lock, CheckCircle2 } from "lucide-react";

type Inputs = {
  fullName: string;
  email: string;
  company: string;
  phone: string;
  interest: string;
  gdprConsent: boolean;
};

const FormView: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
      <div className="max-w-md mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-3">
            Transform Your Business Today
          </h1>
          <p className="text-lg text-gray-600">
            Join 10,000+ companies already growing with our solutions
          </p>
        </div>
        <div className="bg-white rounded-lg shadow-xl p-8">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <progress className="progress progress-info max-w-md mb-4" value="70" max="100"></progress>
              <label className="block text-sm font-medium text-gray-700" htmlFor="fullName">
                Full Name *
              </label>
              <input
                id="fullName"
                type="text"
                {...register("fullName", { required: "Full name is required" })}
                className={`mt-1 block w-full input input-bordered ${
                  errors.fullName ? "border-red-300" : "border-gray-300"
                } focus:ring focus:ring-blue-500`}
              />
              {errors.fullName && (
                <p className="mt-1 text-sm text-red-600">{errors.fullName.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                Business Email *
              </label>
              <input
                id="email"
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email address",
                  },
                })}
                className={`mt-1 block w-full input input-bordered ${
                  errors.email ? "border-red-300" : "border-gray-300"
                } focus:ring focus:ring-blue-500`}
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="company">
                Company Name *
              </label>
              <input
                id="company"
                type="text"
                {...register("company", { required: "Company name is required" })}
                className={`mt-1 block w-full input input-bordered ${
                  errors.company ? "border-red-300" : "border-gray-300"
                } focus:ring focus:ring-blue-500`}
              />
              {errors.company && (
                <p className="mt-1 text-sm text-red-600">{errors.company.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="phone">
                Phone Number
              </label>
              <input
                id="phone"
                type="tel"
                {...register("phone")}
                className="mt-1 block w-full input input-bordered focus:ring focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700" htmlFor="interest">
                Primary Interest
              </label>
              <select
                id="interest"
                {...register("interest")}
                className="mt-1 block w-full select select-bordered focus:ring focus:ring-blue-500"
              >
                <option value="">Select an option</option>
                <option value="consulting">Business Consulting</option>
                <option value="marketing">Digital Marketing</option>
                <option value="analytics">Analytics Solutions</option>
                <option value="other">Other Services</option>
              </select>
            </div>

            <div className="flex items-start">
              <input
                id="gdpr"
                type="checkbox"
                {...register("gdprConsent", { required: "You must accept the privacy policy" })}
                className="checkbox checkbox-info"
              />
              <label className="ml-3 text-sm text-gray-600" htmlFor="gdpr">
                I agree to the <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a> and consent to the processing of my personal data *
              </label>
            </div>
            {errors.gdprConsent && (
              <p className="mt-1 text-sm text-red-600">{errors.gdprConsent.message}</p>
            )}

            <button
              type="submit"
              className="w-full btn btn-info text-white"
            >
              Submit
            </button>
          </form>

          <div className="mt-8 space-y-4">
            <div className="flex items-center justify-center space-x-8 text-gray-500">
              <div className="flex items-center">
                <Shield className="w-5 h-5 mr-2" />
                <span className="text-sm">SSL Secured</span>
              </div>
              <div className="flex items-center">
                <Lock className="w-5 h-5 mr-2" />
                <span className="text-sm">GDPR Compliant</span>
              </div>
            </div>

            <div className="border-t pt-4">
              <p className="text-sm text-gray-500 text-center">
                "Working with them has increased our revenue by 40% in just 3 months!"
                <br />
                <span className="font-medium">- Sarah Johnson, CEO at TechCorp</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormView;
import React from "react";
import { Link } from "react-router";
import logo from "../assets/logo.png";
import { useFirebase } from "../hooks/useFirebase";

const Header: React.FC = () => {
  const { user, logOut } = useFirebase();

  return (
    <header className="flex items-center justify-between w-full px-6 py-4">
      {/* Logo and Title */}
      <div className="flex items-center">
        <img src={logo} alt="HelloForm Logo" className="h-10 w-10 mr-3" />
        <h1 className="text-xl font-black text-gray-900">HelloForm AI</h1>
      </div>

      {/* Right-side Buttons */}
      <div className="flex items-center space-x-4">
        {!user ? (
          // Show Sign In and Create Account Buttons
          <>
            <Link to="/signin">
              <button className="btn btn-sm">Sign In</button>
            </Link>
            <Link to="/signin?create=true">
              <button className="btn btn-sm btn-info text-white">Create Account</button>
            </Link>
          </>
        ) : (
          // Show Profile Dropdown if Logged In
          <div className="dropdown dropdown-end">
            <div tabIndex={0} role="button" className="avatar placeholder">
              <div className="bg-info text-neutral-content w-12 rounded-full">
                <span>EW</span>
              </div>
            </div>
            <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
              <li><a onClick={logOut}>Logout</a></li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
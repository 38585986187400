import React from "react";
import { useSearchParams, Link } from "react-router";
import { useForm } from "react-hook-form";
import { useFirebase } from "../hooks/useFirebase";
import towerImage from "../assets/alex-nghiem-39J8uC7y4iQ-unsplash.jpg"

interface FormData {
  email: string;
  password: string;
  confirmPassword?: string; // Only used in create account mode
}

const SigninView: React.FC = () => {
  const { signIn } = useFirebase();
  const [searchParams] = useSearchParams();
  const isCreateMode = searchParams.get("create") === "true";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      if (isCreateMode) {
        // Handle account creation logic
        console.log("Account creation data:", data);
      } else {
        await signIn(data.email, data.password);
        console.log("Logged in successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const passwordValue = watch("password"); // To validate confirmPassword

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h2 className="text-2xl font-bold text-center mb-4">
        {isCreateMode ? "Signup new account" : "Login to your account"}
      </h2>
      <div className="card card-side bg-base-100 shadow-xl">
        <figure>
          <img
            src={towerImage}
            alt="Futuristic art of metal tower"
            />
        </figure>

        {/* Sign In / Create Account Form */}
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text">Email</span>
              </label>
              <input
                type="email"
                id="email"
                className={`input input-bordered w-full ${
                  errors.email ? "input-error" : ""
                }`}
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && (
                <p className="text-sm text-error mt-1">{errors.email.message}</p>
              )}
            </div>

            <div className="form-control">
              <label htmlFor="password" className="label">
                <span className="label-text">Password</span>
              </label>
              <input
                type="password"
                id="password"
                className={`input input-bordered w-full ${
                  errors.password ? "input-error" : ""
                }`}
                {...register("password", { required: "Password is required" })}
              />
              {errors.password && (
                <p className="text-sm text-error mt-1">{errors.password.message}</p>
              )}
            </div>

            {isCreateMode && (
              <div className="form-control">
                <label htmlFor="confirmPassword" className="label">
                  <span className="label-text">Confirm Password</span>
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className={`input input-bordered w-full ${
                    errors.confirmPassword ? "input-error" : ""
                  }`}
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === passwordValue || "Passwords do not match",
                  })}
                />
                {errors.confirmPassword && (
                  <p className="text-sm text-error mt-1">
                    {errors.confirmPassword.message}
                  </p>
                )}
              </div>
            )}

            <button type="submit" className="btn btn-block btn-info text-white">
              {isCreateMode ? "Create Account" : "Login"}
            </button>
          </form>

          {/* Toggle Sign In / Create Account Link */}
          <div className="card-actions mt-4 ">
            <p className="text-sm">
              {isCreateMode ? "Already have an account?" : "Don't have an account?"}{" "}
              <Link
                to={isCreateMode ? "/signin" : "/signin?create=true"}
                className="link link-hover text-blue-600"
              >
                {isCreateMode ? "Login to existing account" : "Create a new account"}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SigninView;
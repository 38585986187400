import React, { createContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore

const firebaseConfig = {
  apiKey: "AIzaSyC37yVaE5N_2OyPIdIrzZ5Z6NNZh6XB4rQ",
  authDomain: "helloform-1b6b3.firebaseapp.com",
  projectId: "helloform-1b6b3",
  storageBucket: "helloform-1b6b3.firebasestorage.app",
  messagingSenderId: "918374094458",
  appId: "1:918374094458:web:071c3f7cb68219fc68cdc3",
  measurementId: "G-4XC5LWDDYQ"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp); // Initialize Firestore

// Create context
export const FirebaseContext = createContext();

// Firebase Provider component
export const FirebaseProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      console.error('Error signing in:', error);
      throw error;
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  };

  return (
    <FirebaseContext.Provider value={{ user, loading, signIn, logOut, db }}>
      {children}
    </FirebaseContext.Provider>
  );
};
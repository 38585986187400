import React, { useCallback, useState, useEffect } from 'react';
import update from 'immutability-helper';
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useFirebase } from '../hooks/useFirebase';
import { Card } from './Card.tsx';
import type { FC } from 'react';

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

const LIST_ID = 'gljY3ObCHd7bGadTwaU0'

const DnDView: FC = () => {
  const { db } = useFirebase()
  const [cards, setCards] = useState([]);

  useEffect(() => {
    const getInitialCards = async () => {
      const list = await getDoc(doc(db, 'lists', LIST_ID))
      const intialCards = list.data()?.cards

      setCards(intialCards)
    }
    
    getInitialCards()
  }, [])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) => {
      const newCards = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      });
  
      // Persist the new state to Firestore
      updateDoc(doc(db, 'lists', LIST_ID), {
        cards: newCards
      })
  
      return newCards;
    });
  }, []);

  const renderCard = useCallback(
    (card: { id: number; text: string }, index: number) => {
      return (
        <Card
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
        />
      );
    },
    []
  );

  return (
    <div className="w-full max-w-md mx-auto p-4 space-y-2">
      {cards.map((card, i) => renderCard(card, i))}
    </div>
  );
};

export default DnDView;

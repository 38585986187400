import React from "react";
import Header from "./Header.tsx"

const HomeView: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center flex-grow">
        <div className="text-center max-w-xl">
          <h1 className="text-5xl font-black text-gray-900">
            Build Forms with <span className="bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text">AI</span>
          </h1>
          <p className="mt-4 text-lg text-gray-700">
            Smarter online forms, faster workflows — powered by AI.
          </p>
        </div>

        <div className="mt-8 w-full max-w-lg">
          <textarea
            placeholder="Describe your online form idea..."
            className="w-full px-4 py-2 h-32 border border-gray-300 rounded-lg shadow focus:ring-2 focus:ring-blue-500 resize-none"
          ></textarea>
        </div>

        <div className="mt-6 flex flex-wrap gap-4 justify-center">
          <button className="btn btn-outline btn-info btn-sm">📅 Event RSVPs</button>
          <button className="btn btn-outline btn-info btn-sm">📢 Community Feedback</button>
          <button className="btn btn-outline btn-info btn-sm">📫 Newsletter Sign-Ups</button>
        </div>
        <div className="mt-2 flex flex-wrap gap-4 justify-center">
          <button className="btn btn-outline btn-info btn-sm">🚀 Lead Gen</button>
          <button className="btn btn-outline btn-info btn-sm">✏️ Customer Survey</button>
        </div>
      </main>

      {/* Footer */}
      <footer className="mt-16 text-center text-gray-500 text-sm py-4">
        <p>© 2025 Power of Breath. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomeView;
